<template>
  <div>
    <label v-if="label" class="j-text-field-label">{{ label }}</label>
    <label v-if="sublabel" class="j-text-field-sublabel tw-text-light-grey">{{
      sublabel
    }}</label>
    <v-text-field
      class="j-text-field"
      :class="customClasses"
      outlined
      rounded
      solo
      flat
      :loading="loading"
      :placeholder="placeholder"
      :elevation="elevation"
      :light="light"
      :disabled="disabled"
      :color="color || '#2c3e50'"
      :background-color="backgroundColor"
      :type="type"
      :hide-details="hideDetails"
      :error-messages="errorMessages"
      :value="value"
      :clearable="clearable"
      :prepend-inner-icon="prependInnerIcon"
      :append-icon="appendIcon"
      v-model="inputVal"
      :maxlength="maxlength"
      :readonly="readonly"
      :dense="dense"
      @click:append="$emit('toggle')"
      @keyup="$emit('keyup', $event)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "j-text-field",
  props: [
    "elevation",
    "light",
    "disabled",
    "type",
    "formatting",
    "label",
    "sublabel",
    "color",
    "dense",
    "backgroundColor",
    "hideDetails",
    "errorMessages",
    "value",
    "clearable",
    "placeholder",
    "appendIcon",
    "prependInnerIcon",
    "alignCenter",
    "maxlength",
    "readonly",
    "customClasses",
    "loading"
  ],
  data() {
    return {
      timeout: null
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style lang="scss">
.j-text-field-label {
  color: #203848;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 8px;
  display: block;
}

.j-text-field-sublabel {
  color: #203848;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 8px;
  display: block;
}

.j-text-field.v-text-field > .v-input__control > .v-input__slot {
  border: 1px solid #f5f5f5;
}

.j-text-field.align-left.v-text-field
  > .v-input__control
  > .v-input__slot
  input {
  text-align: left;
}

.j-text-field.v-text-field--outlined > .v-input__control > .v-input__slot {
  background-color: #fafafa;
}

.j-text-field.v-text-field--outlined fieldset {
  border-color: #f7f6f5;
}

.j-text-field.tw-text-left.v-text-field
  > .v-input__control
  > .v-input__slot
  input {
  text-align: left;
}

.j-text-field .v-progress-linear {
  left: 10%;
  width: 80%;
}

.j-text-field.tw-bg-input-background.v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  background-color: #f7f6f5 !important;
}
</style>
