import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Root",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/auth",
    // name: "AuthRoute",
    component: () =>
      import(/* webpackChunkName: "authFlow" */ "../views/AuthFlow.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "",
        name: "SignupOrLoginRoute",
        component: () =>
          import(
            /* webpackChunkName: "signupOrLogin" */ "../components/auth/SignupOrLoginForm.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/login",
        name: "LoginRoute",
        component: () =>
          import(
            /* webpackChunkName: "loginForm" */ "../components/auth/LoginForm.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/link-sent",
        name: "LinkSent",
        component: () =>
          import(
            /* webpackChunkName: "loginForm" */ "../components/auth/LinkSent.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/link-resent",
        name: "LinkResent",
        component: () =>
          import(
            /* webpackChunkName: "loginForm" */ "../components/auth/LinkResent.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "password-reset",
        name: "PasswordReset",
        component: () =>
          import(
            /* webpackChunkName: "loginForm" */ "../components/auth/PasswordReset.vue"
          ),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: "/confirm",
    name: "ConfirmEmail",
    component: () =>
      import(/* webpackChunkName: "loginForm" */ "../views/ConfirmEmail.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/auth/signup",
    name: "SignupRoute",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Signup.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/app",
    name: "AppRoute",
    component: Main,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "trip/:id/cancel-confirmation",
        name: "CancelConfirmationRoute",
        component: () => import("../views/CancelConfirmation.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "trips",
        name: "TripsRoute",
        component: () =>
          import(/* webpackChunkName: "trips" */ "../views/Trips.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "past-trips",
        name: "PastTripsRoute",
        component: () =>
          import(/* webpackChunkName: "pastTrips" */ "../views/TripsPast.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "declined-trips",
        name: "DeclinedTripsRoute",
        component: () =>
          import(
            /* webpackChunkName: "declinedTrips" */ "../views/TripsDeclined.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "trips/create",
        name: "TripCreateRoute",
        component: () =>
          import(
            /* webpackChunkName: "tripCreate" */ "../views/TripCreate.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "trips/:id",
        name: "TripViewRoute",
        component: () =>
          import(/* webpackChunkName: "trip" */ "../views/Trip.vue"),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "trips/:id/survey",
        name: "SurveyCreateRoute",
        component: () =>
          import(
            /* webpackChunkName: "surveyCreate" */ "../views/SurveyCreate.vue"
          ),
        children: [
          {
            path: "survey-list",
            name: "SurveyListRoute",
            component: () =>
              import(
                /* webpackChunkName: "surveyList" */ "../components/survey/SurveyList.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "create",
            name: "SurveyCreateRouteForm",
            component: () =>
              import(
                /* webpackChunkName: "createSurveyForm" */ "../components/survey/CreateSurveyForm.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "confirm",
            name: "SurveyConfirmRoute",
            component: () =>
              import(
                /* webpackChunkName: "confirmSurveyForm" */ "../components/survey/ConfirmSurveyForm.vue"
              ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "edit/:surveyId",
            name: "SurveyEditRouteForm",
            component: () =>
              import(
                /* webpackChunkName: "editSurveyForm" */ "../components/survey/SurveyEditRouteForm.vue"
              ),
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: "trips/:id/survey/:surveyId",
        name: "SurveyRoute",
        component: () =>
          import(/* webpackChunkName: "survey" */ "../views/Survey.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "trips/:id/survey-complete",
        name: "SurveyCompleteRoute",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/SurveyComplete.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "trips/:id/survey-results/:surveyId",
        name: "SurveyResults",
        component: () =>
          import(/* webpackChunkName: "survey" */ "../views/SurveyResults.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "trips/:id/crew",
        name: "CrewRoute",
        component: () =>
          import(/* webpackChunkName: "crew" */ "../views/Crew.vue"),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: "list",
            name: "CrewListRoute",
            component: () =>
              import(
                /* webpackChunkName: "crewList" */ "../components/crew/CrewList.vue"
              ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "list/rsvp/edit/:userId",
            name: "CrewEditRoute",
            component: () =>
              import(
                /* webpackChunkName: "crewList" */ "../components/crew/CrewEdit.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: "trips/:id/dates",
        name: "DatesRoute",
        component: () =>
          import(/* webpackChunkName: "dates" */ "../views/Dates.vue"),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: "list",
            name: "DatesListRoute",
            component: () =>
              import(
                /* webpackChunkName: "datesList" */ "../components/dates/DatesList.vue"
              ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "create",
            name: "DatesCreateRoute",
            component: () =>
              import(
                /* webpackChunkName: "datesList" */ "../components/dates/DatesCreate.vue"
              ),
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: "trips/:id/itinerary",
        name: "TripItineraryRoute",
        component: () =>
          import(/* webpackChunkName: "itinerary" */ "../views/Itinerary.vue"),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: "list",
            name: "TripItineraryList",
            component: () =>
              import(
                /* webpackChunkName: "itineraryList" */ "../components/itinerary/ItineraryList.vue"
              ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "create",
            name: "TripItineraryCreate",
            component: () =>
              import(
                /* webpackChunkName: "itineraryEventCreate" */ "../components/itinerary/ItineraryEventCreate.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "edit/:item",
            name: "TripItineraryEdit",
            component: () =>
              import(
                /* webpackChunkName: "itineraryEventEdit" */ "../components/itinerary/ItineraryEventEdit.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: "trips/:id/accommodation",
        name: "AccommodationRoute",
        component: () =>
          import(
            /* webpackChunkName: "accommodation" */ "../views/Accommodation.vue"
          ),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: "list",
            name: "AccommodationList",
            component: () =>
              import(
                /* webpackChunkName: "accommodationList" */ "../components/accommodation/AccommodationList.vue"
              ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "create",
            name: "AccommodationCreate",
            component: () =>
              import(
                /* webpackChunkName: "accommodationCreate" */ "../components/accommodation/AccommodationCreate.vue"
              ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "edit/:uuid",
            name: "AccommodationEdit",
            component: () =>
              import(
                /* webpackChunkName: "accommodationEdit" */ "../components/accommodation/AccommodationEdit.vue"
              ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "finalize",
            name: "AccommodationFinalize",
            component: () =>
              import(
                /* webpackChunkName: "accommodationFinalize" */ "../components/accommodation/AccommodationFinalize.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: "trips/:id/notes",
        name: "NotesRoute",
        component: () =>
          import(/* webpackChunkName: "notes" */ "../views/Notes.vue"),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: "list",
            name: "NotesListRoute",
            component: () =>
              import(
                /* webpackChunkName: "notesList" */ "../components/notes/NotesList.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "create",
            name: "NoteCreate",
            component: () =>
              import(
                /* webpackChunkName: "noteCreate" */ "../components/notes/NoteCreate.vue"
              ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "edit/:noteId",
            name: "NoteEdit",
            component: () =>
              import(
                /* webpackChunkName: "noteEdit" */ "../components/notes/NoteEdit.vue"
              ),
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: "trips/:id/travel-schedules",
        name: "TravelSchedulesRoute",
        component: () =>
          import(
            /* webpackChunkName: "travelSchedules" */ "../views/TravelSchedules.vue"
          ),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: "list",
            name: "TravelSchedulesListRoute",
            component: () =>
              import(
                /* webpackChunkName: "travelSchedulesList" */ "../components/travel-schedules/TravelSchedulesList.vue"
              ),
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: "trips/:id/invite",
        name: "InviteRoute",
        component: () =>
          import(/* webpackChunkName: "invite" */ "../views/Invite.vue"),
        children: [
          {
            path: "preview",
            name: "InviteCrewRoutePreview",
            component: () =>
              import(
                /* webpackChunkName: "inviteCrew" */ "../components/invite/InviteCrew.vue"
              ),
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: "activities/:id",
        name: "ActivitiesRoute",
        component: () =>
          import(
            /* webpackChunkName: "activities" */ "../views/Activities.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "activities/:id/suggest",
        name: "SuggestActivityRoute",
        component: () =>
          import(
            /* webpackChunkName: "suggestActivityRoute" */ "../views/SuggestActivity.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "activities/:id/edit/:uuid",
        name: "EditActivityRoute",
        component: () =>
          import(
            /* webpackChunkName: "editActivity" */ "../views/EditActivity.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "activities/:id/to-itinerary/:uuid?",
        name: "ActivityToItineraryRoute",
        component: () =>
          import(
            /* webpackChunkName: "activityToItinerary" */ "../views/ActivityToItinerary.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "activities/:id/edit-from-itinerary/:item",
        name: "EditActivityFromItineraryRoute",
        component: () =>
          import(
            /* webpackChunkName: "editActivityFromItinerary" */ "../views/EditActivityFromItinerary.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "destinations/:id",
        name: "DestinationsRoute",
        component: () =>
          import(
            /* webpackChunkName: "destinations" */ "../views/Destinations.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "destinations/:id/suggest",
        name: "SuggestDestinationRoute",
        component: () =>
          import(
            /* webpackChunkName: "suggestDestination" */ "../views/SuggestDestination.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "destinations/:id/edit/:uuid",
        name: "EditDestinationRoute",
        component: () =>
          import(
            /* webpackChunkName: "editDestination" */ "../views/EditDestination.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "profile",
        name: "ProfileRoute",
        component: () =>
          import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
        children: [
          {
            path: "home",
            name: "ProfileHome",
            component: () =>
              import(
                /* webpackChunkName: "profileHome" */ "../components/profile/ProfileHome.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "edit",
            name: "EditProfile",
            component: () =>
              import(
                /* webpackChunkName: "editProfile" */ "../components/profile/EditProfile.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "change-password",
            name: "ChangePassword",
            component: () =>
              import(
                /* webpackChunkName: "changePassword" */ "../components/profile/ChangePassword.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "send-link",
            name: "SendLink",
            component: () =>
              import(
                /* webpackChunkName: "sendLink" */ "../components/profile/SendLink.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "delete-account",
            name: "DeleteAccount",
            component: () =>
              import(
                /* webpackChunkName: "deleteAccount" */ "../components/profile/DeleteAccount.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "notifications",
            name: "Notifications",
            component: () =>
              import(
                /* webpackChunkName: "notifications" */ "../components/profile/Notifications.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "available-upgrades",
            name: "AvailableUpgrades",
            component: () =>
              import(
                /* webpackChunkName: "availableUpgrades" */ "../components/profile/AvailableUpgrades.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "purchase-history",
            name: "PurchaseHistory",
            component: () =>
              import(
                /* webpackChunkName: "purchaseHistory" */ "../components/profile/PurchaseHistory.vue"
              ),
            props: true,
            meta: {
              requiresAuth: true
            }
          }
        ],
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "trips/:id/upgrade",
        name: "UpgradeTripRoute",
        component: () =>
          import(
            /* webpackChunkName: "upgradeTripWallet" */ "../views/UpgradeTrip.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "trips/:id/upgrade/purchase",
        name: "UpgradeTripPurchaseRoute",
        component: () =>
          import(
            /* webpackChunkName: "upgradeTripPurchase" */ "../views/UpgradeTripPurchase.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "trips/:id/upgrade/checkout/:checkoutId",
        name: "UpgradeTripCheckoutRoute",
        component: () =>
          import(
            /* webpackChunkName: "upgradeTripCheckout" */ "../views/UpgradeTripCheckout.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "trips/:id/upgrade/confirm",
        name: "UpgradeTripCheckoutConfirmRoute",
        component: () =>
          import(
            /* webpackChunkName: "upgradeTripCheckoutConfirm" */ "../views/UpgradeTripCheckoutConfirm.vue"
          ),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "feed",
        name: "FeedRoute",
        component: () =>
          import(/* webpackChunkName: "feed" */ "../views/Feed.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "support/bug-report",
        name: "SupportBugReport",
        component: () =>
          import(
            /* webpackChunkName: "supportBugReport" */ "../views/SupportBugReport.vue"
          ),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "activity",
        name: "ActivityNotificationsRoute",
        component: () =>
          import(
            /* webpackChunkName: "supportBugReport" */ "../views/ActivityNotifications.vue"
          ),
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/trip-invitation/:uuid",
    name: "Invitation",
    component: () => import("../views/Invitation.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/invitation/:id",
    name: "CreateInvitation",
    component: () => import("../views/Invite.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "create",
        name: "CreateInvitationForm",
        component: () =>
          import(
            /* webpackChunkName: "inviteCrewForm" */ "../components/invite/InviteCrewForm.vue"
          ),
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/trips/:id/rsvp-confirmation-yes",
    name: "RSVPConfirmationYes",
    component: () => import("../views/RSVPConfirmationYes.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/trips/:id/rsvp-confirmation-no",
    name: "RSVPConfirmationNo",
    component: () => import("../views/RSVPConfirmationNo.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/trips/:id/created",
    name: "TripCreatedConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "tripCreatedConfirmation" */ "../views/TripCreatedConfirmation.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/:id/created",
    name: "SurveyCreatedConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "surveyCreatedConfirmation" */ "../components/survey/SurveyCreatedConfirmation.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/cancel-confirmed",
    name: "CancelConfirmed",
    component: () => import("../views/CancelConfirmed.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/trips/:id/survey-confirmation",
    name: "SurveyCreateConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/SurveyCreateConfirmation.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () => import("../views/TermsAndConditions.vue"),
    meta: {
      requiresAuth: false
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
});

export default router;
