<template>
  <div>
    <label
      v-if="label"
      class="j-text-field-label tw-text-dark-green tw-text-xs tw-font-semibold tw-text-left tw-mb-2 tw-block"
      >{{ label }}</label
    >
    <vue-tel-input
      ref="input"
      class="tw-shadow-none tw-mb-2 tw-text-dark-green tw-text-base"
      :autoDefaultCountry="false"
      :defaultCountry="defaultCountry"
      :inputOptions="inputOptions"
      :value="value"
      @blur="onBlur"
      @country-changed="onCountryChange"
      v-model="inputVal"
    ></vue-tel-input>
    <div class="j-tel-error tw-text-xs tw-text-left" v-if="!isValid">
      The number format is incorrect.
    </div>
  </div>
</template>
<script>
export default {
  name: "j-tel-input",
  data() {
    return {
      isValid: true
    };
  },
  props: {
    inputOptions: {
      type: Object
    },
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    defaultCountry: {
      type: String,
      default: "US"
    }
  },
  watch: {
    value: function (val) {
      if (val) this.onBlur();
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    onBlur() {
      if (this.$refs.input.phone) {
        this.isValid = this.$refs.input.phoneObject.valid;
      }
      let obj = {
        valid: this.isValid,
        number: this.$refs.input.phoneObject.number
      };
      this.$emit("blur", obj);
    },
    onCountryChange(e) {
      this.$emit("country-changed", e.dialCode);
    }
  }
};
</script>
<style lang="scss" scoped>
.j-tel-error {
  color: #ff5252;
}

.vue-tel-input {
  border: 1px solid #f5f5f5;
  background-color: #f7f6f5;
  border-radius: 28px;
  min-height: 56px;
}

.vue-tel-input ::v-deep .vti__dropdown {
  &:hover {
    @apply tw-pl-4;
  }
  @apply tw-pl-4;
  border-radius: 28px 0 0 28px;
}

.vue-tel-input:focus-within {
  @apply tw-border-transparent;
}
</style>
