<template>
  <div class="global-alert tw-w-full tw-max-w-460 tw-fixed tw-top-0 tw-z-50">
    <v-alert
      rounded
      dense
      :type="globalAlert.type"
      :value="globalAlert.show"
      bottom
      :timeout="globalAlert.timeout"
      transition="v-expand-x-transition"
    >
      {{ globalAlert.text }}

      <template v-slot:close="{ toggle }">
        <v-btn icon dark @click="dismissAlert(toggle)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-alert>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "GlobalAlert",

  computed: {
    globalAlert() {
      return this.$store.state.meta.globalAlert;
    },
  },

  methods: {
    ...mapActions(["meta/hideGlobalAlert"]),
    dismissAlert() {
      this.$store.dispatch("meta/hideGlobalAlert");
    },
  },
  mounted() {
    setTimeout(() => {
      this.dismissAlert()
    }, this.globalAlert.timeout)
  }
};
</script>

<style lang="scss">
.global-alert .v-alert .v-alert__wrapper .v-icon {
  align-self: center;
}
</style>
