export default {
  namespaced: true,
  state: {
    mainContainer: {
      defaultContainerPaddingDisabled: false
    },
    header: {
      defaultHeaderDisabled: false,
      pageTitle: null,
      bgColor: null,
      fontColor: null,
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: false,
      tripHubButton: false,
      tripHubButtonId: null,
      startDate: null,
      endDate: null,
      destination: null
    },
    style: {
      mainBgColor: "#ffffff"
    },
    surveyQuestions: [],
    currentTrip: {},
    globalAlert: {
      text: null,
      type: null,
      show: false,
      timeout: null
    },
    bottomNavigation: {
      hidden: false,
      button: false,
      isDisabled: true,
      text: "",
      textColor: null,
      bgColor: null,
      method: null
    },
    showConfetti: false,
    readNotifications: false,
    clickedVerificationBanner: false
  },
  mutations: {
    addSurveyQuestions: (state, val) => {
      state.surveyQuestions = val;
    },
    setCurrentTrip: (state, val) => {
      state.currentTrip = val;
    },
    setGlobalAlertType: (state, val) => {
      state.globalAlert.type = val;
    },
    setGlobalAlertText: (state, val) => {
      state.globalAlert.text = val;
    },
    setGlobalAlertTimeout: (state, val) => {
      state.globalAlert.timeout = val;
    },
    setGlobalAlertShow: (state, val) => {
      state.globalAlert.show = val;
    },
    setHeader: (state, val) => {
      state.header.defaultHeaderDisabled = val.defaultHeaderDisabled;
      state.header.pageTitle = val.pageTitle;
      state.header.bgColor = val.bgColor;
      state.header.fontColor = val.fontColor;
      state.header.tagline = val.tagline;
      state.header.iconOne = val.iconOne;
      state.header.iconTwo = val.iconTwo;
      state.header.showBackButton = val.showBackButton;
      state.header.tripHubButton = val.tripHubButton;
      state.header.tripHubButtonId = val.tripHubButtonId;
      state.header.startDate = val.startDate;
      state.header.endDate = val.endDate;
      state.header.destination = val.destination;
      state.header.isPremium = val.isPremium ? val.isPremium : false;
    },
    setMainContainerPadding: (state, val) => {
      state.mainContainer.defaultContainerPaddingDisabled =
        val.defaultContainerPaddingDisabled;
    },
    SET_NAVIGATION_BUTTON: (state, val) => {
      if (val.button !== undefined) state.bottomNavigation.button = val.button;
      if (val.isDisabled !== undefined)
        state.bottomNavigation.isDisabled = val.isDisabled;
      if (val.text !== undefined) state.bottomNavigation.text = val.text;
      if (val.textColor !== undefined)
        state.bottomNavigation.textColor = val.textColor;
      if (val.bgColor !== undefined)
        state.bottomNavigation.bgColor = val.bgColor;
      if (val.method !== undefined) state.bottomNavigation.method = val.method;
    },
    setBottomNavigation: (state, val) => {
      if (val.hidden) state.bottomNavigation.hidden = val.hidden;
      if (val.hidden === false) state.bottomNavigation.hidden = val.hidden;
    },
    setStyle: (state, val) => {
      state.style.mainBgColor = val.mainBgColor;
    },
    setShowConfetti: (state, val) => {
      state.showConfetti = val;
    },
    setReadNotifications: (state, val) => {
      state.readNotifications = val;
    },
    setClickedVerificationBanner: (state, val) => {
      state.clickedVerificationBanner = val;
    }
  },
  actions: {
    showGlobalAlert: ({ commit }, data) => {
      commit("setGlobalAlertType", data.type);
      commit("setGlobalAlertText", data.text);
      commit("setGlobalAlertTimeout", data.timeout);
      commit("setGlobalAlertShow", true);
    },
    hideGlobalAlert: ({ commit }) => {
      commit("setGlobalAlertType", null);
      commit("setGlobalAlertText", null);
      commit("setGlobalAlertTimeout", null);
      commit("setGlobalAlertShow", false);
    },
    fireConfetti: ({ commit }) => {
      commit("setShowConfetti", true);
    }
  }
};
