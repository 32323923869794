import { DateTime, Settings } from "luxon";
Settings.defaultZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const FormattedDateRange = {
  methods: {
    getMonthAndDay(date) {
      return DateTime.fromISO(date, { setZone: true }).toFormat("MMMM d");
    },
    getChipDateText(date) {
      return DateTime.fromISO(date, { setZone: true }).toFormat("EEE MM/dd");
    },
    getFormattedDate(date, type = null) {
      const currentDate = DateTime.now();
      let inputDate = DateTime.fromISO(date);

      // Check if the date is today
      if (currentDate.hasSame(inputDate, "day")) {
        if (type) return "Today";
        return `Today, ${inputDate.toFormat("h:mm a")}`;
      }

      // Check if the date is yesterday
      if (currentDate.minus({ days: 1 }).hasSame(inputDate, "day")) {
        return `Yesterday, ${inputDate.toFormat("h:mm a")}`;
      }

      // If not today or yesterday, format the date normally
      inputDate = DateTime.fromISO(date, { setZone: true });
      return inputDate.toFormat("LLL d, yyyy");
    },
    getFormattedTimeAgo(date) {
      const d = DateTime.fromISO(date);
      const now = DateTime.now();
      const diff = now
        .diff(d, ["days", "hours", "minutes", "seconds"])
        .toObject();

      if (diff.days > 0) {
        return `${Math.round(diff.days)}d ago`;
      } else if (diff.hours > 0) {
        return `${Math.round(diff.hours)}h ago`;
      } else if (diff.minutes > 0) {
        return `${Math.round(diff.minutes)}m ago`;
      } else {
        return `${Math.abs(Math.round(diff.seconds))}s ago`;
      }
    },
    getFormattedDateRange(startDate, endDate) {
      if (!startDate && !endDate) {
        return "TBD";
      }

      const start = DateTime.fromISO(startDate, { setZone: true }).startOf(
        "day"
      );
      const end = DateTime.fromISO(endDate, { setZone: true }).endOf("day");
      if (start.year === end.year) {
        if (start.month === end.month) {
          return start.toFormat("LLL d") + " - " + end.toFormat("d, yyyy");
        } else {
          return start.toFormat("LLL d") + " - " + end.toFormat("LLL d, yyyy");
        }
      } else {
        if (startDate && !endDate) {
          return start.toFormat("LLL d, yyyy") + " - TBD";
        } else if (!startDate && endDate) {
          return "TBD - " + end.toFormat("LLL d, yyyy");
        } else {
          return (
            start.toFormat("LLL d, yyyy") + " - " + end.toFormat("LLL d, yyyy")
          );
        }
      }
    },
    getFormattedDateRangeFromString(option) {
      if (option) {
        const dates = option.text.split(",");
        const start = DateTime.fromISO(dates[0], { setZone: true }).startOf(
          "day"
        );
        const end = DateTime.fromISO(dates[1], { setZone: true }).endOf("day");
        if (start.year === end.year) {
          if (start.month === end.month) {
            return start.toFormat("LLL d") + " - " + end.toFormat("d, yyyy");
          } else {
            return (
              start.toFormat("LLL d") + " - " + end.toFormat("LLL d, yyyy")
            );
          }
        } else {
          return (
            start.toFormat("LLL d, yyyy") + " - " + end.toFormat("LLL d, yyyy")
          );
        }
      }
    },
    getResponseDateFormattedValue(date) {
      const d = DateTime.fromISO(date);
      return d.toFormat("MM/dd/yyyy");
    }
  }
};
