<template>
  <v-text-field
    class="j-text-field-small"
    :outlined="outlined"
    rounded
    solo
    flat
    :placeholder="placeholder"
    :elevation="elevation"
    :light="light"
    :disabled="disabled"
    :dense="dense"
    :color="color || '#2c3e50'"
    :background-color="backgroundColor"
    :type="type"
    :hide-details="hideDetails"
    :error-messages="errorMessages"
    :value="value"
    :clearable="clearable"
    :prepend-inner-icon="prependInnerIcon"
    v-model="inputVal"
    :maxlength="maxlength"
    :readonly="readonly"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    @keyup.enter="$emit('enter')"
  >
  </v-text-field>
</template>

<script>
export default {
  name: "j-text-field-small",
  props: [
    "elevation",
    "light",
    "disabled",
    "type",
    "label",
    "color",
    "dense",
    "backgroundColor",
    "hideDetails",
    "errorMessages",
    "value",
    "clearable",
    "placeholder",
    "prependInnerIcon",
    "alignCenter",
    "maxlength",
    "readonly",
    "outlined",
  ],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.j-text-field-small {
  font-size: 12px;
}

.j-text-field-small.v-text-field > .v-input__control > .v-input__slot {
  border: 1px solid #bdbdbd;
}

.j-text-field-small.v-text-field > .v-input__control > .v-input__slot input {
  text-align: left;
  padding: 4px 0;
}

.j-text-field-small.v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  background-color: #fafafa;
}

.j-text-field-small.v-text-field--outlined fieldset {
  border-color: #f7f6f5;
}
</style>
