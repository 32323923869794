<template>
  <v-row align="center">
    <!-- If showBackButton is true, display back button -->
    <template v-if="showBackButton">
      <v-btn
        outlined
        @click="goBack"
        class="tw-tracking-normal tw-font-figtree tw-text-base tw-font-semibold tw-leading-30 tw-border-transparent tw-text-center tw-rounded-full tw-bg-white tw-ml-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          class="tw-mr-2"
        >
          <path
            d="M7.41016 1.41L2.83016 6L7.41016 10.59L6.00016 12L0.000157404 6L6.00016 -1.23266e-07L7.41016 1.41Z"
            fill="#203848"
          />
        </svg>
        <span>BACK</span>
      </v-btn>
    </template>
    <!-- If tribHubButton -->
    <template v-if="tripHubButton">
      <v-btn
        outlined
        @click="goToTripHub"
        class="tw-tracking-normal tw-font-figtree tw-text-base tw-font-semibold tw-leading-30 tw-border-transparent tw-text-center tw-rounded-full tw-bg-white tw-ml-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          class="tw-mr-2"
        >
          <path
            d="M7.41016 1.41L2.83016 6L7.41016 10.59L6.00016 12L0.000157404 6L6.00016 -1.23266e-07L7.41016 1.41Z"
            fill="#203848"
          />
        </svg>
        <span>{{
          $route.name === "TripViewRoute" ? "MY TRIPS" : "TRIP HUB"
        }}</span>
      </v-btn>
    </template>

    <!-- Else, display the icons -->

    <v-col cols="1" v-if="iconOne" class="tw-min-w-max tw-pr-0">
      <div
        class="tw-flex tw-text-center tw-rounded-full tw-p-1 tw-border-3 tw-border-solid tw-w-10 tw-h-10 tw-leading-10"
        :style="borderClass"
      >
        <v-icon class="tw-w-full tw-h-full" :style="fontClass">{{
          iconOne
        }}</v-icon>
      </div>
    </v-col>
    <v-col cols="1" v-if="iconTwo" class="tw-max-w-full tw-pr-0 tw-pl-2">
      <div
        class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-text-center tw-rounded-full tw-p-1 tw-border-3 tw-border-solid tw-w-10 tw-h-10 tw-leading-10"
        :style="borderClass"
      >
        <!-- Check if iconTwo is an SVG path and render accordingly -->
        <template v-if="isSvgPath(iconTwo)">
          <img :src="iconTwo" alt="Icon" class="tw-w-5 tw-h-5" />
        </template>
        <template v-else>
          <span
            :style="fontClass"
            class="tw-text-center tw-w-full tw-h-full tw-font-bebas-neue tw-leading-26 tw-text-20"
            >{{ iconTwo }}</span
          >
        </template>
      </div>
    </v-col>
    <v-col class="tw-flex tw-justify-end">
      <v-divider
        :style="borderClass"
        class="tw-border-2 tw-border-solid tw-ml-3"
        v-if="$route.name != 'TripViewRoute'"
      ></v-divider>
      <img
        src="@/assets/svgs/upgrade-badge.svg"
        alt="Upgrade trip"
        class="tw-cursor-pointer"
        v-else
        @click="
          $router.push({
            name: 'UpgradeTripRoute',
            params: { id: $route.params.id }
          })
        "
      />
    </v-col>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text class="tw-text-left"
          >Your changes won't be saved.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="tw-text-dark-green" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="tw-text-dark-green" text @click="sendToTripHub()">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "HeaderIconBarr",
  props: [
    "fontColor",
    "iconOne",
    "iconTwo",
    "showBackButton",
    "tripHubButton",
    "tripHubButtonId"
  ],
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    fontClass() {
      if (this.fontColor) return `color: ${this.fontColor} !important`;
      return "";
    },
    borderClass() {
      if (this.fontColor) return `border-color: ${this.fontColor} !important`;
      return "";
    }
  },
  methods: {
    isSvgPath(path) {
      return path && path.endsWith(".svg");
    },
    goBack() {
      if (this.$route.name === "SurveyResults") {
        this.$router.push({ name: "SurveyListRoute" });
      } else {
        this.$router.go(-1);
      }
    },
    goToTripHub() {
      this.dialog = false;
      if (this.$route.name === "SurveyConfirmRoute") {
        this.dialog = true;
      } else if (this.$route.name === "TripViewRoute") {
        this.$router.push({ name: "TripsRoute" });
      } else {
        this.sendToTripHub();
      }
    },
    sendToTripHub() {
      this.dialog = false;
      this.$router.push({
        name: "TripViewRoute",
        params: { id: this.tripHubButtonId }
      });
    }
  }
};
</script>
