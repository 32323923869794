import { required, email, confirmed, min, max } from "vee-validate/dist/rules";
import { extend, setInteractionMode } from "vee-validate";
import { PASSWORD } from "@/enums/validation-regex.js";
setInteractionMode("aggressive");

extend("password", {
  validate: (value) => {
    if (value.length < 8) return false;
    if (!PASSWORD.pattern.test(value)) return false;
    return true;
  },
  message:
    "Your {_field_} must be at least 8 characters long and contain a capital letter, a number and a symbol (!@#%)."
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});

extend("confirmed", {
  ...confirmed,
  message: "Passwords don't match."
});

extend("matchEmail", {
  validate: (value, email) => {
    return value === email[0];
  },
  message: "The email address does not match the user email."
});

extend("min", min);

extend("max", {
  validate(value, { length }) {
    const maxLength = parseInt(length, 10);
    return max.validate(value, { length: maxLength });
  },
  message: "{_field_} must contain less than {length} characters."
});

export default {
  required,
  email,
  confirmed,
  max
};
