<template>
  <div :class="['tw-relative', { 'tw-hidden': bottomNavigationHidden }]">
    <div
      v-if="showButton"
      class="tw-max-w-460 tw-fixed tw-bottom-5 tw-rounded-t-3xl tw-font-figtree tw-font-bold tw-h-20 tw-z-5 tw-w-full tw-bg-white"
      id="bottom-cta-bar"
    >
      <div
        class="tw-w-10/12 tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center tw-mx-auto"
      >
        <v-btn
          x-large
          block
          rounded
          depressed
          :disabled="buttonDisabled"
          @click="buttonMethod"
          :class="['tw-tracking-tighter', buttonTextColor, buttonBgColor]"
          >{{ buttonTitle }}</v-btn
        >
      </div>
    </div>
    <v-bottom-navigation
      v-else
      height="80"
      id="bottom-navigation"
      grow
      fixed
      class="tw-max-w-460 tw-bottom-5 tw-rounded-t-3xl tw-font-figtree tw-font-bold tw-left-2/4"
    >
      <v-btn
        :to="{ name: 'TripsRoute' }"
        exact
        class="tw-bg-white tw-rounded-tl-3xl tw-tracking-tighter"
      >
        <span class="negative-margin">My Trips</span>
        <v-icon size="22" class="tw-mb-2.5">mdi-bag-suitcase</v-icon>
      </v-btn>

      <v-btn
        :to="{ name: 'ProfileHome' }"
        exact
        class="tw-bg-white tw-tracking-tighter"
      >
        Profile
        <v-icon class="tw-mb-2.5">mdi-emoticon-happy</v-icon>
      </v-btn>

      <v-btn
        :to="{ name: 'TripCreateRoute' }"
        exact
        class="tw-bg-white tw-tracking-tighter"
      >
        <span class="tw-mb-2.5">New Trip</span>
        <v-icon size="46">mdi-plus-circle</v-icon>
      </v-btn>

      <v-btn
        :to="{ name: 'SupportBugReport' }"
        exact
        class="tw-bg-white tw-tracking-tighter"
      >
        Support
        <v-icon class="tw-mb-2.5">mdi-frequently-asked-questions</v-icon>
      </v-btn>

      <v-btn
        :to="{ name: 'ActivityNotificationsRoute' }"
        exact
        class="tw-bg-white tw-rounded-tr-3xl tw-tracking-tighter"
      >
        Activity
        <span
          class="tw-absolute tw-bg-red tw-text-white tw-flex tw-items-center tw-justify-center tw-w-4 tw-h-4 indent-0 tw-p-1 tw-rounded-full tw--mt-10 tw-ml-4 tw-text-xxxs tw-z-10"
          v-if="
            !readNotifications &&
            unreadNotifications > 0 &&
            $route.name !== 'ActivityNotificationsRoute'
          "
          >{{ unreadNotifications }}</span
        >
        <v-icon class="tw-mb-2.5">mdi-bell</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: "BottomNavigation",
  data() {
    return {
      unreadNotifications: 0,
      interval: null
    };
  },
  computed: {
    readNotifications() {
      return this.$store.state.meta.readNotifications;
    },
    showButton() {
      return this.$store.state.meta.bottomNavigation.button;
    },
    buttonDisabled() {
      return this.$store.state.meta.bottomNavigation.isDisabled;
    },
    buttonTitle() {
      return this.$store.state.meta.bottomNavigation.text;
    },
    buttonBgColor() {
      return this.$store.state.meta.bottomNavigation.bgColor;
    },
    buttonTextColor() {
      return this.$store.state.meta.bottomNavigation.textColor;
    },
    buttonMethod() {
      return this.$store.state.meta.bottomNavigation.method;
    },
    bottomNavigationHidden() {
      return this.$store.state.meta.bottomNavigation.hidden;
    }
  },
  methods: {
    async fetchNotifications() {
      this.unreadNotifications = 0;
      const notificationsRes = await this.$store.dispatch("notification/find", {
        query: {
          isInApp: true,
          isSeen: false
        }
      });

      if (notificationsRes && notificationsRes.total) {
        this.unreadNotifications = notificationsRes.total;
      }
      this.$store.commit("meta/setReadNotifications", false);
    }
  },
  beforeMount() {
    this.fetchNotifications();
    this.interval = setInterval(this.fetchNotifications, 300000);
  },
  unmounted() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss">
#bottom-cta-bar {
  z-index: 9;
  box-shadow: 0px -3px 23px 0px hsla(0, 0%, 0%, 0.11);
}

#bottom-navigation {
  transform: translateX(-50%) !important;
  box-shadow: 0px -3px 23px 0px hsla(0, 0%, 0%, 0.11);

  .v-btn {
    height: inherit;
  }

  .v-btn:not(.v-btn--active) {
    color: #203848 !important;
  }
  .negative-margin {
    margin-top: -1px;
  }
}

.indent-0 {
  text-indent: 0 !important;
  padding-top: 6px !important;
}
</style>
