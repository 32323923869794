<template>
  <div id="particles"></div>
</template>

<script>
export default {
  name: 'Confetti',

  methods: {
    start() {
      this.$confetti.start({
        windspeedMax: 0,
      });
    },

    stop() {
      this.$confetti.stop();
    },
  },

  mounted() {
    this.start();
    setTimeout(() => {
      this.stop();
      this.$store.commit('meta/setShowConfetti', false);
    }, 2000);
  }
}
</script>

<style lang="scss">

</style>