<template>
  <div
    id="main"
    class="tw-shadow-2xl tw-min-h-screen tw-bg-chartreuse tw-relative"
  >
    <Header
      v-if="!defaultHeaderDisabled"
      :page-title="pageTitle"
      :bg-color="bgColor"
      :font-color="fontColor"
      :tagline="tagline"
      :icon-one="iconOne"
      :icon-two="iconTwo"
      :start-date="startDate"
      :end-date="endDate"
      :destination="destination"
    ></Header>
    <v-container
      class="tw-bg-white tw-rounded-3xl tw-pb-24 tw-pt-10 tw--mt-5 tw-min-h-80vh"
      :class="{
        'tw-p-0': defaultHeaderDisabled,
        'main-container': !defaultContainerPaddingDisabled,
        'no-padding-container': defaultContainerPaddingDisabled && inRouteList
      }"
    >
      <router-view></router-view>
    </v-container>
    <bottom-navigation />
    <global-alert v-if="globalAlert.show" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import BottomNavigation from "@/components/BottomNavigation";
import GlobalAlert from "@/components/alerts/GlobalAlert";

export default {
  name: "Main",

  components: {
    Header,
    BottomNavigation,
    GlobalAlert
  },

  computed: {
    inRouteList() {
      let routes = [
        "SurveyConfirmRoute",
        "SurveyResults",
        "SurveyEditRouteForm",
        "CrewListRoute",
        "Notifications"
      ];
      return routes.includes(this.$route.name);
    },
    defaultHeaderDisabled() {
      return this.$store.state.meta.header.defaultHeaderDisabled;
    },
    defaultContainerPaddingDisabled() {
      return this.$store.state.meta.mainContainer
        .defaultContainerPaddingDisabled;
    },
    pageTitle() {
      return this.$store.state.meta.header.pageTitle;
    },
    bgColor() {
      return this.$store.state.meta.header.bgColor;
    },
    fontColor() {
      return this.$store.state.meta.header.fontColor;
    },
    tagline() {
      return this.$store.state.meta.header.tagline;
    },
    iconOne() {
      return this.$store.state.meta.header.iconOne;
    },
    iconTwo() {
      return this.$store.state.meta.header.iconTwo;
    },
    mainBgColor() {
      return this.$store.state.meta.style.mainBgColor;
    },
    globalAlert() {
      return this.$store.state.meta.globalAlert;
    },
    startDate() {
      return this.$store.state.meta.header.startDate;
    },
    endDate() {
      return this.$store.state.meta.header.endDate;
    },
    destination() {
      return this.$store.state.meta.header.destination;
    }
  }
};
</script>
<style lang="scss">
.no-padding-container {
  padding: 0;
}

@media (max-width: 390px) {
  .main-container {
    @apply tw-px-6;
  }
}
@media (min-width: 391px) {
  .main-container {
    @apply tw-px-8;
  }
}
</style>
