<template>
  <div class="j-chip-group">
    <label class="j-chip-group-label">{{ label }}</label>
    <v-chip-group
      center-active
      :multiple="multiple"
      v-model="inputVal"
      @input="$emit('input', value)"
    >
      <v-chip
        v-for="(item, index) in items"
        @click="$emit('input', item)"
        :key="index"
        :value="item"
        :ripple="false"
        :id="item"
        >{{ isValidDate(item) ? getChipDateText(item) : getItem(item) }}</v-chip
      >
    </v-chip-group>
  </div>
</template>
<script>
import { ACTIVITY_TYPES } from "@/enums/activities.js";
import { DateTime } from "luxon";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
export default {
  name: "j-chip-group",
  mixins: [FormattedDateRange],
  props: ["items", "label", "value", "multiple"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    getItem(item) {
      if (item === ACTIVITY_TYPES.FOOD) {
        return "EAT & DRINK";
      }
      return item;
    },
    isValidDate(str) {
      const luxonDateTime = DateTime.fromISO(str, { setZone: true });
      return luxonDateTime.isValid;
    }
  }
};
</script>
<style lang="scss">
.j-chip-group {
  overflow-x: hidden;
}
.j-chip-group .j-chip-group-label {
  color: #203848;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 8px;
  display: block;
}
.j-chip-group
  .v-chip-group
  .v-slide-group__wrapper
  .v-slide-group__content
  .v-chip {
  background-color: white;
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0px 1px 4px 0px #00000021;
  &--active {
    background-color: #e6ffa1;
    box-shadow: none;
    &::before {
      opacity: 0;
    }
  }
}

@media screen and (max-width: 1023px) {
  .j-chip-group .v-chip-group .v-slide-group__prev,
  .v-chip-group .v-slide-group__next {
    display: none;
  }
}
</style>
