<template>
  <div>
    <label v-if="label" class="j-text-field-label">{{ label }}</label>
    <label v-if="sublabel" class="j-text-field-sublabel tw-text-light-grey">{{
      sublabel
    }}</label>
    <v-textarea
      class="j-textarea"
      outlined
      rounded
      no-resize
      :placeholder="placeholder"
      :elevation="elevation"
      :light="light"
      :disabled="disabled"
      :color="color || '#2c3e50'"
      :background-color="backgroundColor"
      :type="type"
      :hide-details="hideDetails"
      :error-messages="errorMessages"
      :value="value"
      :clearable="clearable"
      v-model="inputVal"
      @focus="$emit('focus')"
    >
    </v-textarea>
  </div>
</template>

<script>
export default {
  name: "j-textarea",
  props: [
    "elevation",
    "light",
    "disabled",
    "type",
    "label",
    "sublabel",
    "color",
    "backgroundColor",
    "hideDetails",
    "errorMessages",
    "value",
    "clearable",
    "placeholder"
  ],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style lang="scss">
.j-textarea.v-text-field--outlined > .v-input__control > .v-input__slot {
  background-color: #f7f6f5;
}

.j-textarea.v-text-field--outlined fieldset {
  border-color: #f7f6f5;
}
</style>
