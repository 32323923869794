<template>
  <v-app
    id="app"
    class="tw-font-figtree tw-text-center tw-antialiased tw-bg-[#E8DAFE]"
  >
    <div
      class="mobile-container tw-max-w-460 tw-w-full tw-h-full tw-mx-auto tw-relative"
    >
      <router-view />
      <div class="beta-banner">Beta Version: {{ betaVersion }}</div>
    </div>
    <confetti v-if="showConfetti" />
  </v-app>
</template>

<script>
import { Settings } from "luxon";
import Confetti from "./components/alerts/Confetti.vue";

export default {
  name: "App",
  components: {
    Confetti
  },
  computed: {
    showConfetti() {
      return this.$store.state.meta.showConfetti;
    },
    betaVersion() {
      return process.env.VUE_APP_VERSION
        ? process.env.VUE_APP_VERSION
        : "0.0.00000.00000";
    }
  },
  beforeMount() {
    Settings.defaultZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
  }
};
</script>

<style lang="scss">
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

#app {
  background: linear-gradient(
    159deg,
    rgba(232, 218, 254, 1) 26%,
    rgba(237, 249, 199, 1) 100%
  );
}

.fixed {
  position: fixed;
  width: 100%;
}

.v-application {
  min-height: calc(100vh - 20px);
}

.v-btn {
  @apply tw-tracking-normal;
}

.v-btn .v-btn__content {
  text-transform: none;
  font-weight: bold;
}

.v-btn.primary .v-btn__content {
  color: #2c3e50;
}

.beta-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 3px 10px;
  background-color: #203848;
  color: #ffffff;
  font-size: 10px;
  z-index: 5;
}

.floating-overlay {
  height: calc(100vh - 196px);
  width: calc(100% + 64px);
  position: absolute;
  top: -39px;
  left: -32px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.95);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  z-index: 10;
  overflow-x: hidden;

  &.survey {
    width: 100%;
    left: 0;
    height: calc(100vh - 184px);
    background: #fff;
  }
}

.floating-wrapper {
  width: 100%;
  height: 1px;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  position: fixed;
  bottom: 180px;
  z-index: 9;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
</style>
