<template>
  <div>
    <v-alert
      icon="mdi-account"
      dense
      dismissible
      type="warning"
      v-if="user && !user.isEmailVerified && !$route.path.includes('auth')"
      class="tw-mb-0 tw-rounded-none tw-fixed tw-max-w-460 tw-z-10"
    >
      <p class="tw-mb-0" v-if="resent">
        We’ve re-sent your link to
        <span class="tw-font-bold">{{ user.email }}</span
        >. Be sure to check your promotions and other folders.
      </p>
      <p class="tw-mb-0" v-else>
        Click the link in your email to verify your account. Can't find it?
        Check spam or resend.
        <br /><span
          class="tw-mt-56 tw-underline tw-cursor-pointer"
          @click="resendLink"
          >Re-Send Link</span
        >
      </p>
    </v-alert>
    <v-alert
      icon="mdi-account"
      dense
      dismissible
      type="warning"
      class="tw-mb-0 tw-rounded-none tw-fixed tw-max-w-460 tw-z-10"
      v-if="
        user &&
        !user.isPhoneVerified &&
        user.isEmailVerified &&
        !$route.path.includes('auth') &&
        user.receiveSMS &&
        !$store.state.meta.clickedVerificationBanner
      "
    >
      <p
        class="tw-mb-0 tw-underline tw-cursor-pointer"
        @click="openEditProfile()"
      >
        Reminder: Verify your phone number to receive SMS notifications
      </p>
    </v-alert>
    <div
      class="header tw-pt-10 tw-px-7 tw-pb-16"
      :class="isPremium || isPremiumBackground ? 'j-premium' : ''"
      :style="bgClass"
    >
      <header-icon-barr
        :font-color="getFontColor"
        :icon-one="iconOne"
        :icon-two="iconTwo"
        :show-back-button="showBackButton"
        :trip-hub-button="tripHubButton"
        :trip-hub-button-id="tripHubButtonId"
        v-if="!isPremium"
      ></header-icon-barr>
      <div
        class="tw-flex tw-w-full tw-items-center tw-justify-between tw--mt-6 tw-mb-2"
        v-else
      >
        <v-btn
          outlined
          @click="$router.push({ name: 'TripsRoute' })"
          class="tw-tracking-normal tw-font-figtree tw-text-base tw-font-semibold tw-leading-30 tw-border-transparent tw-text-center tw-rounded-full tw-bg-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            class="tw-mr-2"
          >
            <path
              d="M7.41016 1.41L2.83016 6L7.41016 10.59L6.00016 12L0.000157404 6L6.00016 -1.23266e-07L7.41016 1.41Z"
              fill="#203848"
            />
          </svg>
          <span>MY TRIPS</span>
        </v-btn>
        <img
          src="@/assets/svgs/premium/letsjetty-premium-white.svg"
          alt="Premium Trip"
        />
      </div>
      <v-row v-if="editingHeaderTitle">
        <v-col cols="12">
          <div
            class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full tw-mb-4"
          >
            <div class="tw-relative tw-w-full">
              <j-text-field
                v-model="headerTitle"
                light
                background-color="#fafafa"
                color="#203848"
                hide-details
                placeholder="Add Destination"
                class="tw-w-full tw-mr-2"
                prepend-icon="mdi-web"
              />
              <v-icon
                class="tw-absolute tw-top-4 tw-right-4 tw-text-warm-grey"
                @click="
                  editingHeaderTitle = false;
                  headerTitle = '';
                "
                >mdi-window-close</v-icon
              >
            </div>
            <v-btn v-if="trip" icon class="tw-w-10 tw-h-10" @click="save">
              <v-icon
                size="20"
                :class="{
                  'tw-text-warm-grey': !isPremium && !isPremiumBackground,
                  'tw-text-white': isPremium || isPremiumBackground
                }"
                >mdi-check</v-icon
              >
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="tw-text-left">
          <div class="tw-flex tw-items-end">
            <h3
              class="tw-max-w-80 logo-font tw-font-bebas-neue tw-leading-none tw-text-44 tw-tracking-tight"
              :style="fontClass"
            >
              {{ headerTitle.length > 0 ? headerTitle : pageTitle }}
            </h3>
            <img
              class="tw-mb-2 tw-ml-4 tw-cursor-pointer"
              src="@/assets/svgs/edit.svg"
              alt="Edit"
              v-if="titleIsEditable && (isOwner || isOrganizer)"
              @click="setHeaderTitle()"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="tagline">
        <v-col>
          <p class="tw-text-base" :style="fontClass">
            {{ tagline }}
          </p>
        </v-col>
      </v-row>
      <div
        v-if="startDate && endDate"
        class="tw-flex tw-flex-row tw-items-center tw-justify-start"
        :class="{
          'tw-text-dark-green': !isPremium,
          'tw-text-white': isPremium
        }"
      >
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="tw-mr-4"
        >
          <path
            d="M0 4.63072H12.4966V11.5768C12.4966 12.3427 11.8959 12.966 11.1577 12.966H1.33892C0.600729 12.966 0 12.3427 0 11.5768V4.63072ZM11.1577 0.926144H10.2651V0.463072C10.2651 0.207456 10.0656 0 9.81875 0C9.57195 0 9.37245 0.207456 9.37245 0.463072V0.926144H3.12415V0.463072C3.12415 0.207456 2.92465 0 2.67784 0C2.43103 0 2.23153 0.207456 2.23153 0.463072V0.926144H1.33892C0.600729 0.926144 0 1.54944 0 2.31536V3.70457H12.4966V2.31536C12.4966 1.54944 11.8959 0.926144 11.1577 0.926144Z"
            :fill="isPremium ? '#fff' : '#203848'"
          />
        </svg>
        <p class="tw-mb-0">{{ getFormattedDateRange(startDate, endDate) }}</p>
      </div>
      <div
        v-if="destination"
        class="tw-flex tw-flex-row tw-items-center tw-justify-start"
        :class="{
          'tw-text-dark-green': !isPremium,
          'tw-text-white': isPremium
        }"
      >
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="tw-mr-4"
        >
          <path
            d="M6 0C4.9297 5.24731e-05 3.8789 0.275681 2.95653 0.79831C2.03416 1.32094 1.2738 2.07155 0.754277 2.97231C0.234756 3.87308 -0.0250138 4.89121 0.00189745 5.92116C0.0288087 6.95111 0.341422 7.95539 0.907327 8.82987L6 16L11.0927 8.82987C11.6586 7.95539 11.9712 6.95111 11.9981 5.92116C12.025 4.89121 11.7652 3.87308 11.2457 2.97231C10.7262 2.07155 9.96584 1.32094 9.04347 0.79831C8.1211 0.275681 7.0703 5.24731e-05 6 0ZM6 7.90041C5.5635 7.90041 5.13679 7.77582 4.77385 7.54238C4.41091 7.30893 4.12803 6.97713 3.96099 6.58894C3.79395 6.20074 3.75024 5.77358 3.8354 5.36147C3.92056 4.94936 4.13075 4.57081 4.43941 4.2737C4.74807 3.97658 5.14132 3.77425 5.56943 3.69227C5.99755 3.6103 6.44131 3.65237 6.84459 3.81317C7.24786 3.97397 7.59255 4.24627 7.83506 4.59563C8.07757 4.945 8.20701 5.35575 8.20701 5.77593C8.20701 6.33938 7.97448 6.87975 7.56059 7.27817C7.1467 7.67659 6.58534 7.90041 6 7.90041Z"
            :fill="trip && trip.isPremium ? '#fff' : '#203848'"
          />
        </svg>

        <p class="tw-mb-0">{{ destination }}</p>
      </div>
      <v-btn
        block
        :to="{ name: 'TripItineraryList' }"
        rounded
        x-large
        :color="!isPremium ? '#0036F5' : 'primary'"
        large
        class="tw-tracking-normal tw-mt-4"
        v-if="$route.name === 'TripViewRoute'"
        :class="{ 'tw-bg-chartreuse': isPremium, 'tw-text-white': !isPremium }"
      >
        <v-icon class="tw-absolute tw-left-0">mdi-calendar-month</v-icon>View
        Trip Itinerary
      </v-btn>
    </div>
  </div>
</template>

<script>
import HeaderIconBarr from "@/components/HeaderIconBarr";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";

export default {
  name: "Header",
  mixins: [FormattedDateRange],
  props: [
    "pageTitle",
    "bgColor",
    "fontColor",
    "tagline",
    "iconOne",
    "iconTwo",
    "startDate",
    "endDate",
    "destination"
  ],
  data() {
    return {
      editingHeaderTitle: false,
      headerTitle: "",
      resent: false
    };
  },
  components: {
    HeaderIconBarr
  },
  watch: {
    $route: function () {
      if (!this.titleIsEditable) {
        this.editingHeaderTitle = false;
        this.headerTitle = "";
      }
    }
  },
  computed: {
    isPremiumBackground() {
      if (
        this.$route.name === "AvailableUpgrades" ||
        this.$route.name === "PurchaseHistory" ||
        this.$route.name === "UpgradeTripRoute" ||
        this.$route.name === "UpgradeTripCheckoutRoute" ||
        (this.trip &&
          this.trip.isPremium &&
          (this.$route.name === "SurveyListRoute" ||
            this.$route.name === "SurveyResults" ||
            this.$route.name === "NotesListRoute"))
      )
        return true;
      return false;
    },
    isPremium() {
      if (
        !this.trip ||
        !this.trip.isPremium ||
        this.$route.name !== "TripViewRoute"
      )
        return false;
      return true;
    },
    bgClass() {
      if (this.isPremium || this.isPremiumBackground) return "";
      if (this.bgColor) return `background-color: ${this.bgColor} !important`;
      return "";
    },
    fontClass() {
      if (this.isPremium || this.isPremiumBackground)
        return `color: #fff !important`;
      if (this.fontColor) return `color: ${this.fontColor} !important`;
      return "";
    },
    getFontColor() {
      if (this.isPremium || this.isPremiumBackground) return `#fff`;
      return this.fontColor;
    },
    showBackButton() {
      return this.$store.state.meta.header.showBackButton;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    tripHubButton() {
      return this.$store.state.meta.header.tripHubButton;
    },
    tripHubButtonId() {
      return this.$store.state.meta.header.tripHubButtonId;
    },
    user() {
      return this.$store.state.auth.user;
    },
    isOwner() {
      if (!this.trip) return false;
      return this.user.id === this.trip.ownerId;
    },
    isOrganizer() {
      if (!this.trip || !this.user.trips || this.user.trips.length === 0)
        return false;
      let currentTrip = this.user.trips.find((t) => t.id === this.trip.id);
      if (!currentTrip) return false;
      return currentTrip.trip_invite.roles.includes("owner");
    },
    titleIsEditable() {
      return (
        this.$route.name === "TripItineraryList" ||
        this.$route.name === "TripViewRoute" ||
        this.$route.name === "SurveyResults"
      );
    }
  },

  methods: {
    openEditProfile() {
      this.$store.commit("meta/setClickedVerificationBanner", true);
      if (this.$route.name !== "EditProfile")
        this.$router.push({ name: "EditProfile" });
    },
    setHeaderTitle() {
      if (this.$route.name === "SurveyResults") {
        this.headerTitle = this.pageTitle;
      } else {
        this.headerTitle = this.trip.title;
      }

      this.editingHeaderTitle = true;
    },
    save() {
      if (this.$route.name === "SurveyResults") {
        this.$store
          .dispatch("survey/patch", [
            this.$route.params.surveyId,
            {
              title: this.headerTitle
            }
          ])
          .then(() => {
            this.editingHeaderTitle = false;
            this.$forceUpdate();
          });
      } else {
        this.$store
          .dispatch("trip/patch", [
            this.trip.id,
            {
              title: this.headerTitle
            }
          ])
          .then(() => {
            this.editingHeaderTitle = false;
            this.$forceUpdate();
          });
      }
    },
    async resendLink() {
      await this.$store
        .dispatch("user-verify-email-resend/create", {
          email: this.user.email
        })
        .then(() => {
          this.resent = true;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>
