import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import customRules from "@/plugins/veevalidate";
import "@/components/globals/_globals";
import router from "./router";
import store from "./store";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueConfetti from "vue-confetti";

// Extend VeeValidate with the imported custom rules
Object.keys(customRules).forEach((ruleName) => {
  extend(ruleName, customRules[ruleName]);
});

// Use VeeValidate components globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;
Vue.use(VueTelInput);
Vue.use(VueConfetti);

// Define route constants
const PASSWORD_RESET_ROUTE = "/app/auth/password-reset/";
const VERIFY_ROUTE = "/app/auth/verify/";
const AUTH_ROUTE = "/auth";
const TRIPS_ROUTE = "/app/trips";
const PASSWORD_RESET_REGEX = /\/app\/auth\/password-reset\/([0-9a-fA-F-]+)/;
const UUID_REGEX = /\/app\/auth\/verify\/([0-9a-fA-F-]+)/;

router.beforeEach((to, from, next) => {
  localStorage.setItem("previous_route", from.path);
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  // Password Reset Flow
  if (to.path.startsWith(PASSWORD_RESET_ROUTE)) {
    const passwordResetUUID = to.path.match(PASSWORD_RESET_REGEX)[1];
    store
      .dispatch("consume-password-reset/get", passwordResetUUID)
      .then(() => {
        const resetData = store.getters["consume-password-reset/list"][0];
        const { isUsed, isExpired } = resetData;
        if (isUsed) {
          handlePasswordResetError(next, "The email link was used already.");
        } else if (isExpired) {
          handlePasswordResetError(next, "The email link expired.");
        } else {
          next({ name: "PasswordReset" });
        }
      })
      .catch(handleError("Password reset"));
  }
  // Email Verification Flow
  else if (to.path.startsWith(VERIFY_ROUTE)) {
    const confirmationUUID = to.path.match(UUID_REGEX)[1];
    store
      .dispatch("user-verify-email/create", { uuid: confirmationUUID })
      .then(() => {
        next(TRIPS_ROUTE);
      })
      .catch(handleError("Email verification"));
  }
  // Authentication Flow
  else if (!isAuthenticated) {
    store
      .dispatch("auth/authenticate")
      .then(() => {
        if (to.path.includes("auth") || to.path === "/") {
          next(TRIPS_ROUTE);
        } else {
          next();
        }
      })
      .catch((error) => {
        if (!error.message.includes("Could not find stored JWT")) {
          console.log("Authentication error", error);
        }
        to.meta.requiresAuth ? next(AUTH_ROUTE) : next();
      });
  } else {
    next();
  }
});

// Helper function to handle password reset errors
function handlePasswordResetError(next, message) {
  next(AUTH_ROUTE);
  store.dispatch("meta/showGlobalAlert", {
    type: "error",
    text: message,
    timeout: 2000
  });
}

// Helper function to handle errors in promise chains
function handleError(context) {
  return (error) => {
    console.log(`${context} error`, error);
  };
}

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App)
}).$mount("#app");
